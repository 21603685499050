
import { User } from "@/store/user/types";
import { Component, Vue } from "vue-property-decorator";
import { ROOT_ACTIONS } from "@/store/actions";
import { USER_GETTERS } from "@/store/user/getters";
import { Timestamp, collection, doc, getDoc, getDocs, query, where, addDoc } from "firebase/firestore";
import { firestore } from "@/firebase";
import { startOfDay, endOfDay } from 'date-fns';
import { saveAs } from 'file-saver';
import axios from "axios";
@Component({
    components: {}
})
export default class Home extends Vue {

    loading = false

    mounted() {
        this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Data', target: '/admin/data' }])
    }

    get user(): User {
        return this.$store.getters[USER_GETTERS.ACTING_USER]
    }

    async submit() {
        this.loading = true
        const { data } = await axios.get('/data-export?key=export-data-api', {
            responseType: 'blob'
        })
        
        saveAs(data, 'hub-data-export.csv')
        this.loading = false
       
    }
}

