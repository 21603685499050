
import { Component, Vue } from 'vue-property-decorator';
import { Activity, ActivityType, ProgramFormula, Phase, Program, ProgramType } from "@/types";
import { ROOT_ACTIONS } from "@/store/actions";
import { programTypes } from '@/variables'
import { activityTypes } from '@/variables';
import { collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { firestore } from '@/firebase';

@Component
export default class EditTemplate extends Vue {

  PHASE_COUNT = 3;

  phaseTab = 0;
  _activityTypes = activityTypes;
  _programTypes = programTypes;



  phases = [] as Phase[];

  activities = [] as Activity[];

  getHoursForPhase(phase: Phase): number {
    const hours = this.activities.filter(e => e.phaseId === phase.id).map(e => e.plannedHours).reduce((a, b) => a! + b!, 0);
    if (isNaN(hours as number)) return 0;
    return hours as number;
  }

  addNewActivityToPhase(phaseId?: string) {
    if (!phaseId) return;
    this.activities.push({
      name: "",
      description: "",
      type: ActivityType.INDIVIDUAL_COACHING,
      isOnline: false,
      plannedHours: 0,
      end: null,
      start: new Date(),
      required: false,
      phaseId: phaseId,
    });
  }

  program: Partial<Program> = {
    title: "",
    description: "",
    formula: ProgramFormula.ALGEMEEN_OPZEG,
    type: ProgramType.GROUP,
    language: 'nl-be',
  };


  loading = {
    program: true,
    submit: false,
  };

  headers = [
    { text: "Titel", value: "title" },
    { text: "Type", value: "type" },
  ];

  async mounted() {
    const programDoc = await getDoc(doc(firestore, 'program-templates', this.$route.params.id));
    const phasesDocs = await getDocs(query(collection(firestore, 'phases'), where('programId', '==', this.$route.params.id), orderBy('index', 'asc')))

    for (let phaseDoc of phasesDocs.docs) {
      this.phases.push({
        ...phaseDoc.data(),
        id: phaseDoc.id,
      } as Phase);
    }

    const activityDocs = await getDocs(collection(firestore, 'activities'));
    const activities = activityDocs.docs.map(e => ({ ...e.data(), id: e.id } as Activity));
    for (let activity of activities) {
      this.activities.push(activity);
    }

    const program = {
      ...programDoc.data(),
      language: programDoc.data()!.language || 'nl-be',
      id: programDoc.id,
    }

    this.program = program;
    this.loading.program = false;
  }

  validateInputs(): boolean {
    if (this.program.title == '') return false;
    return true;
  }

  async submit() {
    try {
      if (!this.validateInputs()) {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: 'warning',
          text: 'Ongeldige invoer'
        })
        return;
      }
      this.loading.submit = true;


      this.phases.forEach(phase => {
        phase.hours = this.getHoursForPhase(phase);
      })

      await updateDoc(doc(firestore, 'program-templates', this.$route.params.id,), {
        title: this.program.title,
        description: this.program.description || '',
        formula: this.program.formula || ProgramFormula.OTHER,
        type: this.program.type,
        language: this.program.language,
      });


      for (let phase of this.phases) {
        await updateDoc(doc(firestore, 'phases', phase.id as string), {
          title: phase.title || '',
          description: phase.description || '',
          hours: this.getHoursForPhase(phase)
        })

        for (let activity of this.activities.filter(e => e.phaseId == phase.id)) {
          await updateDoc(doc(firestore, 'activities', activity.id as unknown as string), {
            name: activity.name || '',
            description: activity.description || '',
            type: activity.type || ActivityType.INDIVIDUAL_COACHING,
            plannedHours: activity.plannedHours,
          })
        }
      }


      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: 'Template aangepast',
      });

      this.$router.push('/admin/presence/templates')
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
    } finally {
      this.loading.submit = false;
    }
  }

  languages = [
    {
      text: 'Nederlands',
      value: 'nl-be'
    },
    {
      text: 'Frans',
      value: 'fr'
    }
  ]
} 
