
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";
import { auth, firestore } from "@/firebase";
import {
  browserLocalPersistence,
  signInWithEmailLink,
  isSignInWithEmailLink,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
} from "firebase/auth";
import {
  getDoc,
  doc,
  query,
  collection,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { USER_ACTIONS } from "@/store/user/actions";
import { User } from "@/store/user/types";
import { Activity, ActualActivity } from "@/types";
@Component
export default class Login extends Vue {
  step = 1;

  activity = null as Activity | null;
  actualActivity = null as ActualActivity | null;

  get authenticated() {
    return this.$store.state.user.authenticated;
  }

  async mounted() {
    await this.fetchActivity();
  }

  async fetchActivity() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, []);
    const activityId = this.$route.query.activity as string;
    if (!activityId) {
      this.$router.push("/login");
      return;
    }

    const actualActivity = await getDoc(
      doc(firestore, "actual-activities", activityId)
    );
    if (!actualActivity.exists()) {
      this.$router.push("/login");
      return;
    }

    this.actualActivity = {
      id: actualActivity.id,
      ...actualActivity.data(),
    } as ActualActivity;

    const activity = await getDoc(
      doc(firestore, "activities", actualActivity.data().activityId)
    );
    if (!activity.exists()) {
      this.$router.push("/login");
      return;
    }

    this.activity = {
      id: activity.id,
      ...activity.data(),
    } as Activity;
  }

  loading = false;
  async confirmActivity() {
    this.loading = true;
    if (!this.actualActivity) {
      return;
    }

    await updateDoc(
      doc(firestore, "actual-activities", this.actualActivity.id!),
      {
        participantConfirmed: true,
      }
    );

    await this.fetchActivity();
    this.loading = false;
  }
}
