import { Timestamp } from "firebase/firestore";

export interface Program {
  coach: string
  end: string;
  start: string;
  formula: ProgramFormula
  id: string
  isTemplate: boolean
  isArchived: boolean,
  description: string;
  participant: string;
  type: ProgramType,
  title: string,
  state: StateType,
  language: string
}

export interface GenericState {
  id: string,
  date: Timestamp,
  comment: string,
  type: StateType
  user: string | null
}

export enum StateType {
  PLANNED = 'PLANNED',
  STARTED = 'STARTED',
  ENDED = 'ENDED',
  SUSPENDED = 'SUSPENDED',
  COMPLETED = 'COMPLETED',
  RESUMED = 'RESUMED',
  ARCHIVED = 'ARCHIVED'
}


// enum mappings
export enum ActivityType {
  INDIVIDUAL_COACHING = 'INDIVIDUAL_COACHING',
  WEBINAR = 'WEBINAR',
  CLASSIC_TRAINING = 'CLASSIC_TRAINING',
  WORKSHOP = 'WORKSHOP',
  SELF_LEARNING = 'SELF_LEARNING',
  ASSESMENT = 'ASSESMENT',
}

export enum ProgramFormula {
  ALGEMEEN_OPZEG = 'ALGEMEEN_OPZEG',
  ALGEMEEN_VERBREKING = 'ALGEMEEN_VERBREKING',
  BIJZONDER = 'BIJZONDER',
  MEDISCH_OVERMACHT = 'MEDISCH_OVERMACHT',
  VRIJWILLIG = 'VRIJWILLIG',
  COLLECTIEF = 'COLLECTIEF',
  OTHER = 'OTHER'
}

export enum ProgramType {
  GROUP = 'GROUP',
  INDIVIDUAL = 'INDIVIDUAL',
  MIXED = 'MIXED'
}

export interface Phase {
  id?: string,
  title: string;
  description: string;
  hours: number;
  start: Timestamp,
  end: Timestamp,
  index: number
  programId: string,
}

export interface Activity {
  id?: string,
  type: ActivityType,
  name: string,
  plannedHours: number,
  description: string;
  isOnline: boolean,
  required: boolean,
  start: Date | string,
  end: Date | null,
  phaseId: string,
}

export interface ActualActivity {
  id?: string,
  actualHours: number,
  date: Timestamp,
  comment: string,
  participantPresent: boolean,
  participantConfirmed: boolean
  activityId: string,
}


export enum EMAIL_NOTIFICATION_TYPE {
  COACH_CREATED_ACTIVITY = 'COACH_CREATED_ACTIVITY'
}

export interface EmailNotificationSetting {
  id: string;
  UserId: string;
  type: EMAIL_NOTIFICATION_TYPE;
}

export enum CONTENT_TYPE {
  VIDEO = "VIDEO",
  YOUTUBE_VIDEO = "YOUTUBE_VIDEO",
  PDF = 'PDF'
}

export interface ContentItem {
  id: string;
  title: string;
  type: CONTENT_TYPE;
  user: string;
  property: string;
  language?: string;
  archived: boolean
}

export interface ContentAssignment {
  id?: string;
  user: string;
  requestedBy: string;
  completed: boolean;
  contentId: string;
  phase?: string;
  hoursAdded: boolean;
}

export enum EVENT_TYPE {
  WEBINAR = "WEBINAR",
}

export interface CalendarEvent {
  id?: string;
  title: string;
  start: Timestamp;
  end: Timestamp;
  coach: string;
  createdBy: string;
  type: EVENT_TYPE;
  teamsUrl: string;
  description: string,
  surveySent?: boolean
}

export interface Mail {
  from: string;
  to: string[];
  cc: string[];
  subject: string;
  body: string;
  sent: boolean;
  error?: any;
  date: Timestamp;
}