
import { User } from '@/store/user/types';
import { addHours, parse, format } from 'date-fns';
import { Vue, Component, VModel, Watch } from 'vue-property-decorator';
import { ROOT_ACTIONS } from '@/store/actions';
import { addDoc, collection, doc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { firestore } from '@/firebase';

@Component
export default class CreateEventDialog extends Vue {
  @VModel({ required: true }) show!: boolean;

  coaches: User[] = [];

  loading = false;

  inputs = {
    title: '',
    coach: null as null | User,
    description: '',
    updateMeeting: true,
  }

  picker = {
    date: format(new Date(), 'yyyy-MM-dd'),
    time: '09:00'
  }

  @Watch('inputs.coach')
  onCoachSelect() {
    if (this.inputs.coach && this.inputs.coach.email) {
      if (!this.inputs.coach?.email.includes('@itinera-talentcoaching.be')) {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: 'warning',
          text: 'Kan geen event aanmaken voor deze coach, gelieve een coach te selecteren van met een @itinera-talentcoaching.be emailadres'
        })
      }
    }
  }

  async mounted() {
    const coaches = await getDocs(query(collection(firestore, 'users'), where('type', 'in', ['COACH', 'ADMIN'])))

    this.coaches = coaches.docs.map((e) => {
      return {
        ...e.data(),
        id: e.id,
        fullName: `${e.data().firstname} ${e.data().lastname}`,
      } as User;
    });
  }

  resetFields() {
    this.inputs = {
      title: '',
      coach: null,
      description: '',
      updateMeeting: true,
    }
    this.picker = {
      date: format(new Date(), 'yyyy-MM-dd'),
      time: '09:00'
    }
  }

  async submit() {
    try {
      this.loading = true;
      if (this.inputs.coach) {
        const start = parse(`${this.picker.date} ${this.picker.time}`, 'yyyy-MM-dd HH:mm', new Date());
        const end = addHours(start, 2);

        const doc = await addDoc(collection(firestore, 'webinars'), {
          title: this.inputs.title,
          start,
          end,
          coach: this.inputs.coach.id,
          description: this.inputs.description,
        })

        onSnapshot(doc, (doc) => {
          if (doc.exists()) {
            if (doc.data().teamsUrl) {
              this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'success',
                text: 'Webinar ingepland'
              })
              this.loading = true;
              this.show = false;
              this.resetFields();
              this.$emit('refresh')
            }
          }
        })
      }
    } catch (error) {
      console.log(error)
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'Error'
      })
      this.loading = false;
    }
  }

}
